<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家信息维护</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="main">
        <el-form
          :model="query"
          ref="queryRef"
          :rules="formRules"
          label-width="100px"
        >
          <!-- <el-form-item label="选择停车场" prop="pkIds">
            <el-select
              v-model="query.pkIds"
              filterable
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in PkInfoSelect"
                :key="item.id"
                :label="item.pkName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="商家名称" prop="shopName">
            <el-input
              v-model="query.shopName"
              placeholder="请输入商家名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="linkMan">
            <el-input
              v-model="query.linkMan"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input
              v-model="query.tel"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              v-model="query.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="query.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
  import { findPkInfoSelect } from '@/api/Parkinglot.js';
  import { findByIdxx, updateByShopIdxx } from '@/api/coupon.js';
  export default {
    data() {
      var checkphone = (rule, value, callback) => {
        // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
        if (value === '') {
          callback(new Error('请输入手机号'));
        } else if (!this.isCellPhone(value)) {
          // 引入methods中封装的检查手机格式的方法
          callback(new Error('请输入正确的手机号!'));
        } else {
          callback();
        }
      };
      return {
        query: {
          id: '', // id
          // pkIds: '', // 停车场
          shopName: '', // 商家名称
          linkMan: '', // 联系人
          tel: '', // 联系电话
          address: '', // 详细地址
          remarks: '', // 备注
        },
        formRules: {
          // 添加表单的验证规则
          // pkIds: [
          //   { required: true, message: '请选择停车场名称', trigger: 'blur' },
          // ],
          shopName: [
            { required: true, message: '请输入商家名称', trigger: 'blur' },
            {
              min: 1,
              max: 99,
              message: '长度在 1 到 99 个字符',
              trigger: 'blur',
            },
          ],
          linkMan: [
            { required: true, message: '请输入联系人', trigger: 'blur' },
            {
              min: 1,
              max: 99,
              message: '长度在 1 到 99 个字符',
              trigger: 'blur',
            },
          ],
          tel: [
            {
              required: true,
              validator: checkphone,
              trigger: ['blur', 'change'],
            },
          ],
        },
        // PkInfoSelect: [], // 保存停车场下拉框
      };
    },
    created() {
      this.findByIdxx();
    },
    methods: {
      isCellPhone(val) {
        let mobileReg = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
        if (!mobileReg.test(val)) {
          return false;
        } else {
          return true;
        }
      },
      findByIdxx() {
        // 商家身份-商家管理
        findByIdxx().then((res) => {
          if (res.data.code === '200') {
            if (res.data.data) {
              this.query.id = res.data.data.id;
              this.query.shopName = res.data.data.shopName;
              this.query.linkMan = res.data.data.linkMan;
              this.query.tel = res.data.data.tel;
              this.query.address = res.data.data.address;
              this.query.remarks = res.data.data.remarks;
              // this.query.pkIds = res.data.data.shopInfoPks.map((item) => {
              //   return item.pkId
              // })
            }
          }
        });
      },
      onSubmit() {
        this.$refs.queryRef.validate((valid) => {
          if (!valid) return;
          // this.query.pkIds = this.query.pkIds.join('_')
          updateByShopIdxx(this.query).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('修改成功');
              this.findByIdxx();
            }
          });
        });
      },
      // findPkInfoSelect() {
      //   // 停车场下拉框
      //   findPkInfoSelect({ allRecord: 0 }).then((res) => {
      //     if (res.data.code === '200') {
      //       this.PkInfoSelect = res.data.data
      //       this.findByIdxx()
      //     }
      //   })
      // },
    },
  };
</script>
<style lang="scss" scoped>
  .main {
    width: 50%;
  }
</style>
